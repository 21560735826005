import { useEffect, useState, useRef } from "react";
import AlertMessage from "../../Components/LoadingBar/AlertMessage";
import DeafultLoadingBar from "../../Components/LoadingBar/DefaultLoadingBar";
import * as XLSX from 'xlsx';

import image1 from '../../global/C0276/Image1.png'
import image2 from '../../global/C0276/Image2.png'

import css from '../../css/C0276/DataMatching.module.css'
import { IC0276OrderRecord, IC0276StockRecord } from "../../Interface/interface";

const DataMatchingPage = () => {

    const inputRef = useRef<HTMLInputElement>(null);

    const [isLoading, setLoading] = useState<boolean>(false);
    const [isShowAlert, setShowAlert] = useState<boolean>(false);
    const [isAlertType, setAlertType] = useState<string>('Info');
    const [isAlertMessage, setAlertMessage] = useState<string>('');
    const [isFileType, setFileType] = useState<string>("1");

    const [isStockRecord, setStockRecord] = useState<IC0276StockRecord[]>([]);
    const [isStockExcelDate, setStockExcelDate] = useState<string>('');
    const [isStockTotalQty, setStockTotalQty] = useState<number>(0);
    const [isOrderRecord, setOrderRecord] = useState<IC0276OrderRecord[]>([]);
    const [isOrderExcelDate, setOrderExcelDate] = useState<string>('');
    const [isOrderTotalQty, setOrderTotalQty] = useState<number>(0);
    const [isMissingRecord, setMissingRecord] = useState<IC0276StockRecord[]>([]);


    useEffect(() => {
        console.log("isStockRecord", isStockRecord);
        //reset input file
        const input = document.getElementById('input') as HTMLInputElement;
        if (input) {
            input.value = "";
        }
    }, [isStockRecord, setStockRecord])

    useEffect(() => {
        if (isShowAlert === true) {
            setTimeout(() => {
                setShowAlert(false)
            }, 8000)
        }
    }, [isShowAlert])


    const handleCompareJson = () => {
        // Compare JSON data here
        const mismatches: IC0276StockRecord[] = [];

        isStockRecord.forEach((stock) => {
            const matchingOrder = isOrderRecord.find((order) => (order.Lot_No === stock.Lot_No && (order.Transporter.includes(stock.Transporter) || stock.Transporter.includes(order.Transporter))) || stock.Status.includes("取消"));
            if (matchingOrder) {
                if (stock.Qty !== parseInt(matchingOrder.Qty) || stock.Status.includes("取消")) {
                    console.log("Mismatch found:", stock.Lot_No);
                    console.log("Stock Qty:", stock.Qty);
                    console.log("Order Qty:", parseInt(matchingOrder.Qty));
                    mismatches.push({
                        DN_No: stock.DN_No,
                        Lot_No: stock.Lot_No,
                        Descript: stock.Descript,
                        T_Qty: stock.T_Qty,
                        Qty: stock.Qty,
                        Transporter: stock.Transporter,
                        To1: stock.To1,
                        To2: stock.To2,
                        To3: stock.To3,
                        Status: stock.Status,
                        Reason: stock.Reason
                    });
                }
            }
        });

        if (mismatches.length > 0) {
            setMissingRecord(mismatches);
            setAlertMessage(`Found ${mismatches.length} mismatched record(s).`);
            setAlertType("Warning");
            setShowAlert(true);
        } else {
            setAlertMessage("All matching records have the same quantity.");
            setAlertType("Info");
            setShowAlert(true);
        }
        for (let i = 0; i < isStockRecord.length; i++) {
            const LoNo = isStockRecord[i].Lot_No;
            const checkitem = isOrderRecord.some(item => item.Lot_No === LoNo);
            if (checkitem) {
                console.log(isStockRecord[i].Lot_No);
            }
        }

    }

    const excelDateToJSDate = (serial: number): string => {
        const utc_days = Math.floor(serial - 25569); // 25569 是 1970-01-01 的 Excel 序號
        const utc_value = utc_days * 86400; // 秒
        const date_info = new Date(utc_value * 1000);
        return date_info.toISOString().split("T")[0]; // YYYY-MM-DD
    };

    const extractDate = (text: string): string => {
        const match = text.match(/\d{4}-\d{2}-\d{2}/);
        return match ? match[0] : text;
    };

    const handleInputFile = async () => {
        let startRow = 0;
        let endRow = 0;
        let tempData: IC0276StockRecord[] = [];
        let temOrderData: IC0276OrderRecord[] = [];

        const input = document.getElementById('input') as HTMLInputElement;
        if (!input || !input.files || input.files.length === 0) {
            console.error("Input element not found or no file selected");
            return;
        }

        const file = input.files[0];
        setLoading(true);

        try {
            // 1) 用 FileReader 读取文件
            const reader = new FileReader();
            reader.onload = (evt: ProgressEvent<FileReader>) => {
                if (!evt.target?.result) return;

                // 2) 读取到二进制数据后，用 XLSX 进行解析
                const data = evt.target.result;
                // 如果是 binary string，可以使用 { type: 'binary' }
                // 如果是 array buffer，可以使用 { type: 'array' }
                const workbook = XLSX.read(data, { type: "binary" });

                // 3) 假设你只需要解析第一张表
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                // 4) 以“二维数组”方式读取，用 header: 1 可以直接拿到数组格式（类似 readXlsxFile）
                //    如果你希望自动转换为对象格式，也可用 sheet_to_json(worksheet)。
                const rows = XLSX.utils.sheet_to_json<any[]>(worksheet, { header: 1 });
                console.log(rows);

                if (isFileType === "1") {

                    if (rows[0].length !== 0) {
                        setAlertMessage(`您上傳的文件格式錯誤，請重新上傳正確的文件`);
                        setAlertType("Warning");
                        setShowAlert(true);
                        return;
                    }

                    for (let i = 0; i < rows.length; i++) {
                        if (rows[i][1] === "出倉編號\nD/N NO:") {
                            startRow = i + 1;
                        }

                        if (rows[i][1] === "貨物總件數Total Number of Packages") {
                            endRow = i - 1;
                            setStockTotalQty(rows[i][11]);
                        }
                    }

                    //Get the date from the first row eg. ": 45728"
                    const dateRow = rows[8][18];
                    setStockExcelDate(excelDateToJSDate(dateRow));
                    // const dateMatch = dateRow.match(/:\s*(\d{5})/);
                    // if (dateMatch) {
                    //     const dateString = dateMatch[1];
                    //     const date = new Date(1900, 0, parseInt(dateString) - 1);
                    //     const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
                    //     const formattedDate = date.toLocaleDateString('zh-TW', options);
                    //     setStockExcelDate(formattedDate);
                    // }

                    for (let i = startRow; i <= endRow; i++) {
                        const row = rows[i];
                        if (row && row[1] && row[1].length > 0) {
                            const stockRecord: IC0276StockRecord = {
                                DN_No: row[1],
                                Lot_No: row[5],
                                Descript: row[9],
                                T_Qty: row[11],
                                Qty: row[12],
                                Transporter: row[14],
                                To1: row[15],
                                To2: row[17],
                                To3: row[18],
                                Status: row[19],
                                Reason: row[20]
                            };
                            tempData.push(stockRecord);
                        }
                    }

                    if (tempData.length === 0) {
                        setAlertMessage("No data found in the selected file.");
                        setAlertType("Warning");
                        setShowAlert(true);
                        return;
                    }

                    setStockRecord(tempData);
                } else {

                    if (rows[0].length === 0) {
                        setAlertMessage(`您上傳的文件格式錯誤，請重新上傳正確的文件`);
                        setAlertType("Warning");
                        setShowAlert(true);
                        return;
                    }

                    for (let i = 0; i < rows.length; i++) {
                        if (rows[i][0] === "日期\r\nLot No.") {
                            startRow = i + 1;
                        }

                        if (rows[i][5] === "合計:") {
                            endRow = i - 1;
                            setOrderTotalQty(rows[i][6]);
                        }
                    }

                    //Get the date from the first row eg. ": 2025-03-12\r\n貨品編號"
                    const dateRow = rows[0][1];
                    setOrderExcelDate(extractDate(dateRow));

                    for (let i = startRow; i <= endRow; i++) {
                        const row = rows[i];

                        if (row && row[0] && row[0].length > 0) {
                            if (!row[3].includes("頁號") && !row[3].includes("貨品名稱")) {
                                const orderRecord: IC0276OrderRecord = {
                                    Lot_No: row[0] && typeof row[0] === 'string' && row[0].includes("*") ? row[0].replace("*", "") : row[0],
                                    Product_Code: row[1],
                                    Product_Name: row[3],
                                    Customer_Name: row[4],
                                    Transporter_No: row[5],
                                    Qty: row[6],
                                    Price: row[7],
                                    Transporter: row[8] === "到取" ? row[4] : row[8],
                                    Location: row[9],
                                };
                                temOrderData.push(orderRecord);
                            }
                        }
                    }

                    if (temOrderData.length === 0) {
                        setAlertMessage("No data found in the selected file.");
                        setAlertType("Warning");
                        setShowAlert(true);
                        return;
                    }

                    setOrderRecord(temOrderData);
                }

                // 6) 更新状态
                //setStockRecord(processedData);
            };

            // 读为 binary string（此方式兼容度较广）
            // reader.readAsBinaryString(file);
            reader.readAsArrayBuffer(file);

        } catch (error) {
            console.error("Error reading file", error);
            setAlertMessage("Failed to read the file.");
            setAlertType("Error");
            setShowAlert(true);
        } finally {
            setLoading(false);
        }
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        if (event.dataTransfer.files.length > 0 && inputRef.current) {
            const file = event.dataTransfer.files[0];

            // Create DataTransfer and assign to input
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
            inputRef.current.files = dataTransfer.files;

            // Trigger input change manually
            const changeEvent = new Event("change", { bubbles: true });
            inputRef.current.dispatchEvent(changeEvent);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault(); // 必須阻止預設事件，才能觸發 drop
    };

    const content = (
        <>
            {isShowAlert && <AlertMessage ismessageType={isAlertType} isAlertMessage={isAlertMessage} />}
            {isLoading && <DeafultLoadingBar />}

            <section className={css.TopSection}>
                <p>一步核對</p>

                {(isStockRecord.length > 0 && isFileType === "1") && (
                    <div className={css.NextStepDiv} onClick={() => setFileType("2")}>
                        <p>下一步</p>
                    </div>
                )}

                {(isOrderRecord.length > 0 && isFileType === "2") && (
                    <div className={css.NextStepDiv} onClick={() => { setFileType("3"); handleCompareJson() }}>
                        <p>進行對比</p>
                    </div>
                )}

                {(isOrderRecord.length > 0 && isFileType === "3") && (
                    <div className={css.NextStepDiv} onClick={() => { setFileType("1");}}>
                        <p>重新比對</p>
                    </div>
                )}


            </section>

            {isFileType === "1" && (
                <>
                    <section className={css.MainSection}>
                        <div className={css.ShowFileImage}>
                            <img src={image1} alt="simple file" />
                        </div>
                        <div className={css.InputFileDiv}>
                            <div className={css.SubTitle}>
                                <p>上傳(提貨確認書)</p>
                            </div>

                            <div
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                                className={css.DropZone}
                            >
                                <p>把文件移動到這裏</p>
                            </div>

                            <input
                                type="file"
                                id="input"
                                ref={inputRef}
                                onChange={handleInputFile}
                                style={{ display: "none" }}
                            />

                            <button className={css.uploadButton} onClick={() => inputRef.current?.click()}>點擊此上載文件</button>
                        </div>

                    </section>

                    <section className={css.DisplaySection}>
                        <div className={css.TableDiv}>
                            <table>
                                <thead>
                                    <th>出倉編號</th>
                                    <th>存倉編號</th>
                                    <th>產品名稱</th>
                                    <th>數量</th>
                                    <th>承運</th>
                                    <th>交</th>
                                    <th>交</th>
                                    <th>交</th>
                                    <th>狀態</th>
                                    <th>原因</th>
                                </thead>
                                <tbody>
                                    {isStockRecord.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.DN_No}</td>
                                            <td>{item.Lot_No}</td>
                                            <td>{item.Descript}</td>
                                            <td>{item.Qty}</td>
                                            <td>{item.Transporter}</td>
                                            <td>{item.To1}</td>
                                            <td>{item.To2}</td>
                                            <td>{item.To3}</td>
                                            <td>{item.Status}</td>
                                            <td>{item.Reason}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </>
            )}


            {isFileType === "2" && (
                <>
                    <section className={css.MainSection}>
                        <div className={css.ShowFileImage}>
                            <img src={image2} alt="simple file" />
                        </div>
                        <div className={css.InputFileDiv}>
                            <div className={css.SubTitle}>
                                <p>上傳(每日批貨出倉狀態表)</p>
                            </div>

                            <div
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                                className={css.DropZone}
                            >
                                <p>把文件移動到這裏</p>
                            </div>

                            <input
                                type="file"
                                id="input"
                                ref={inputRef}
                                onChange={handleInputFile}
                                style={{ display: "none" }}
                            />

                            <button className={css.uploadButton} onClick={() => inputRef.current?.click()}>點擊此上載文件</button>
                        </div>

                    </section>

                    <section className={css.DisplaySection}>
                        <div className={css.TableDiv}>
                            <table>
                                <thead>
                                    <th>Lot No.</th>
                                    <th>貨品編號</th>
                                    <th>產品名稱</th>
                                    <th>客戶名稱</th>
                                    <th>發票/送貨單</th>
                                    <th>數量</th>
                                    <th>單價</th>
                                    <th>運輸</th>
                                    <th>地點</th>
                                </thead>
                                <tbody>
                                    {isOrderRecord.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.Lot_No}</td>
                                            <td>{item.Product_Code}</td>
                                            <td>{item.Product_Name}</td>
                                            <td>{item.Customer_Name}</td>
                                            <td>{item.Transporter_No}</td>
                                            <td>{item.Qty}</td>
                                            <td>{item.Price}</td>
                                            <td>{item.Transporter}</td>
                                            <td>{item.Location}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </>
            )}

            {isFileType === "3" && (
                <>
                    <section className={css.OutputSection}>
                        <div className={css.CompareTitleDiv}>
                            <p>比較結果</p>
                        </div>
                        <div className={css.OutputDiv}>
                            <p>提貨確認書日期: {isStockExcelDate}</p>
                            <p>批貨日期: {isOrderExcelDate}</p>

                            <div className={css.OutputResult}>
                                {isStockExcelDate === isOrderExcelDate ?
                                    <p style={{ color: 'green' }}>日期一致</p>
                                    :
                                    <p style={{ color: 'red' }}>日期不一致</p>
                                }
                            </div>
                        </div>

                        <div className={css.OutputDiv}>
                            <p>提貨確認書總數量: {isStockTotalQty}</p>
                            <p>批貨總數量: {isOrderTotalQty}</p>

                            <div className={css.OutputResult}>
                                {isStockTotalQty === isOrderTotalQty ?
                                    <p style={{ color: 'green' }}>數量一致</p>
                                    :
                                    <p style={{ color: 'red' }}>數量不一致</p>
                                }
                            </div>
                        </div>
                    </section>

                    <section className={css.DisplaySection}>
                        <div className={css.TableDiv}>
                            <table>
                                <thead>
                                    <th>出倉編號</th>
                                    <th>存倉編號</th>
                                    <th>產品名稱</th>
                                    <th>數量</th>
                                    <th>承運</th>
                                    <th>交</th>
                                    <th>交</th>
                                    <th>交</th>
                                    <th>狀態</th>
                                    <th>原因</th>
                                </thead>
                                <tbody>
                                    {isMissingRecord.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.DN_No}</td>
                                            <td>{item.Lot_No}</td>
                                            <td>{item.Descript}</td>
                                            <td>{item.Qty}</td>
                                            <td>{item.Transporter}</td>
                                            <td>{item.To1}</td>
                                            <td>{item.To2}</td>
                                            <td>{item.To3}</td>
                                            <td>{item.Status}</td>
                                            <td>{item.Reason}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </>
            )}

        </>
    );

    return content;
}

export default DataMatchingPage;
