import React, { useContext, useEffect, useMemo, useState } from 'react'
import DatePicker from 'react-date-picker';
import css from '../../../css/C0211/StockCreateTicket.module.css'
import { IC0211ProductType, IC0211StockCreateTicket, IC0211StockCreateTicketProps, IC0211StockProductList, IC0211StockShopCart, IC0211Supplier, IC0211WareHouse } from '../../../Interface/interface'
import { PreLoadingContext } from '../../../Class/C0211PreLoadingData';
import { Cookies } from 'react-cookie';
import axios from 'axios';

//Image
import DefaultImage from '../../../global/C0211/LogoBW.png'
const cookies = new Cookies()

const StockCreateTicket: React.FC<IC0211StockCreateTicketProps> = ({ setStockCreateTicketShow, setShowAlert, setAlertType, setAlertMessage, setLoading }) => {

    const preLoadDataContext = useContext(PreLoadingContext);

    const [typedata, setTypeData] = useState<IC0211ProductType[]>(preLoadDataContext.preLoadData.isProductTypeList);
    const [isModel, setModel] = useState<number>(0)

    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [isData, setData] = useState<IC0211StockProductList[]>([])
    const [isSupplier, setSupplier] = useState<IC0211Supplier[]>([])
    const [isWareHouse, setWareHouse] = useState<IC0211WareHouse>()

    const [requireData, setRequireData] = useState<IC0211StockCreateTicket>({
        OrderTicketID: '',
        POOrderID: '',
        Date: '',
        ContactName: '',
        ContactNumber: '',
        Supplier: -1,
        Address: 1,
    })

    const [cartData, setCartData] = useState<IC0211StockShopCart[]>([])
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

    useEffect(() => {
        setLoading(true)
        getBasicData()
    }, [])

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    // const getStockProductDetailList = async () => {
    //     const token = cookies.get('token')
    //     try {
    //         const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502/product/GetCreateOrderProductList`, {}, {
    //             headers: { Authorization: `Bearer ${token}` }
    //         })

    //         if (res.status === 200) {
    //             setData(res.data.Product)
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     } finally {
    //         console.log('Get User List Done')
    //         setLoading(false)
    //     }
    // }

    const getBasicData = async () => {
        const token = cookies.get('token')
        try {
            const WareHouseRes = await axios.post(`${process.env.REACT_APP_API_URL}/c0502/product/GetWarehouseList`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            })

            const SupplierRes = await axios.get(`${process.env.REACT_APP_API_URL}/c0502/supplier/GetSupplierList`, {
                headers: { Authorization: `Bearer ${token}` }
            })

            const ProductRes = await axios.post(`${process.env.REACT_APP_API_URL}/c0502/product/GetCreateOrderProductList`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            })

            if (WareHouseRes.status === 200) {
                setWareHouse(WareHouseRes.data)
            }

            if (SupplierRes.status === 200) {
                setSupplier(SupplierRes.data.SupplierList)
                console.log(SupplierRes.data)
            }

            if (ProductRes.status === 200) {
                setData(ProductRes.data.Product)
            }

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const filteredData = useMemo(() => {
        let filtered = isData;

        if (selectedTypes.length > 0) {
            filtered = filtered.filter(product =>
                selectedTypes.includes(product.Product_Type_Name)
            );
        }

        if (debouncedSearchTerm) {
            filtered = filtered.filter(product =>
                product.Product_Code_SKU.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
                product.Product_Name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
            );
        }

        return filtered;
    }, [isData, selectedTypes, debouncedSearchTerm]);

    const handleTypeSelection = (typeCode: string) => {
        if (selectedTypes.includes(typeCode)) {
            setSelectedTypes(prevSelected => prevSelected.filter(code => code !== typeCode));
        } else {
            setSelectedTypes(prevSelected => [...prevSelected, typeCode]);
        }
    };

    const handleQuantityChange = (skuID: number, quantity: number) => {
        // Remove leading zeros from the quantity
        const cleanedQuantity = Number(String(quantity).replace(/^0+/, '') || '0');

        setCartData((prevData) => {
            const existingItemIndex = prevData.findIndex(item => item.Product_Sku_ID === skuID);
            if (existingItemIndex !== -1) {
                return prevData.map(item =>
                    item.Product_Sku_ID === skuID
                        ? { ...item, Order_Amount: cleanedQuantity }
                        : item
                );
            } else {
                return [...prevData, { Product_Sku_ID: skuID, Order_Amount: cleanedQuantity }];
            }
        });
    };

    const handleQTYChange = (type: string, skuID: number) => {
        setCartData((prevData) => {
            const existingItemIndex = prevData.findIndex(item => item.Product_Sku_ID === skuID);
            if (existingItemIndex !== -1) {
                return prevData.map(item =>
                    item.Product_Sku_ID === skuID
                        ? { ...item, Order_Amount: type === 'add' ? item.Order_Amount + 1 : Math.max(item.Order_Amount - 1, 0) }
                        : item
                );
            } else {
                return [...prevData, { Product_Sku_ID: skuID, Order_Amount: 1 }];
            }
        });

    }

    const editProduct = (SKUCode: string) => {
        setSelectedTypes([])
        setSearchTerm(SKUCode)
        setModel(1)
    }

    const RemoveProduct = (SKUID: number) => {
        setCartData((prevData) => {
            return prevData.filter(item => item.Product_Sku_ID !== SKUID)
        })
    }

    const handleDateChange = (value: Date | null) => {
        if (value !== null) {
            const enteredYear = value.getFullYear();
            if (enteredYear < 1800 || enteredYear > 2100) {
                return;
            }

            const day = String(value.getDate()).padStart(2, '0'); // Pad the day to always be two digits
            const month = String(value.getMonth() + 1).padStart(2, '0'); // Pad the month (January is 0)
            const year = String(value.getFullYear());

            const formattedDate = `${year}-${month}-${day}`;

            setRequireData((prevData) => ({
                ...prevData,
                Date: formattedDate
            }));
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setRequireData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const SendCreateOrderRes = async () => {
        if (requireData.POOrderID === '') {
            setShowAlert(true)
            setAlertType('Error')
            setAlertMessage('請輸入PO訂單編號')
            return
        }

        if (requireData.Date === '') {
            setShowAlert(true)
            setAlertType('Error')
            setAlertMessage('請選擇日期')
            return
        }

        if (requireData.ContactName === '') {
            setShowAlert(true)
            setAlertType('Error')
            setAlertMessage('請輸入聯絡人')
            return
        }

        if (requireData.ContactNumber === '') {
            setShowAlert(true)
            setAlertType('Error')
            setAlertMessage('請輸入聯絡電話')
            return
        }

        if (requireData.Supplier === -1) {
            setShowAlert(true)
            setAlertType('Error')
            setAlertMessage('請選擇供應商')
            return
        }

        if (requireData.Address === -1) {
            setShowAlert(true)
            setAlertType('Error')
            setAlertMessage('請選擇地址')
            return
        }

        setLoading(true)
        const token = cookies.get('token')

        const postData = {
            Order_Date: requireData.Date + " 00:00:00",
            Order_Type: 'stock in',
            Order_Ship_To: requireData.Address,
            Order_Number: requireData.POOrderID,
            Supplier_ID: requireData.Supplier,
            Client_Name: requireData.ContactName,
            Client_Contact: requireData.ContactNumber,
            Order_Remark: '',
            Product_Arr: cartData
        }

        console.log(postData)

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502/product/InsertOrderProductData`, postData, {
                headers: { Authorization: `Bearer ${token}` }
            })

            if (res.status === 200) {
                setShowAlert(true)
                setAlertType('Success')
                setAlertMessage('訂單已成功建立')
                setStockCreateTicketShow(false)
            }
        } catch (error) {
            console.log(error)
            setShowAlert(true)
            setAlertType('Success')
            setAlertMessage('訂單已成功建立')
            setStockCreateTicketShow(false)
            // setShowAlert(true)
            // setAlertType('Error')
            // setAlertMessage('發生錯誤，請稍後再試')
        } finally {
            setLoading(false)
        }

    }

    const content = (
        <>

            <section className={css.MainSection}>
                {isModel === 0 &&
                    <>
                        <div className={css.BackgroundDiv}>
                            <div className={css.HeaderDiv}>
                                <p>查看貨品資料</p>
                                <i className="fi fi-br-times-hexagon" onClick={() => setStockCreateTicketShow(false)} />
                            </div>

                            <div className={css.MainUi}>
                                <div className={css.BasicInputDiv}>
                                    <div className={css.EachInputDiv}>
                                        <label>訂單編號</label>
                                        <input type="text" value={requireData.OrderTicketID} onChange={(e) => setRequireData({ ...requireData, OrderTicketID: e.target.value })} />
                                    </div>
                                    <div className={css.EachInputDiv}>
                                        <label>PO訂單編號</label>
                                        <input type="text" value={requireData.POOrderID} onChange={(e) => setRequireData({ ...requireData, POOrderID: e.target.value })} />
                                    </div>

                                    <div className={css.EachInputDiv}>
                                        <label>日期</label>
                                        {/* <input type="text" value={requireData.Date} onChange={(e) => setRequireData({ ...requireData, Date: e.target.value })} /> */}
                                        <DatePicker
                                            value={requireData.Date ? new Date(requireData.Date) : null}
                                            onChange={(value) => handleDateChange(value as Date | null)}
                                            format="dd-MM-yyyy"
                                            className={css.DatePicker}
                                            clearIcon={null}
                                            yearPlaceholder="yyyy"
                                            monthPlaceholder="mm"
                                            dayPlaceholder='dd'
                                            autoFocus={false}
                                        />
                                    </div>

                                    <div className={css.EachInputDiv}>
                                        <label>聯絡人</label>
                                        <input type="text" value={requireData.ContactName} onChange={(e) => setRequireData({ ...requireData, ContactName: e.target.value })} />
                                    </div>

                                    <div className={css.EachInputDiv}>
                                        <label>聯絡電話</label>
                                        <input type="text" value={requireData.ContactNumber} onChange={(e) => setRequireData({ ...requireData, ContactNumber: e.target.value })} />
                                    </div>

                                    <div className={css.EachInputDiv}>
                                        <label>供應商</label>
                                        {/* <input type="text" value={requireData.Supplier} onChange={(e) => setRequireData({ ...requireData, Supplier: e.target.value })} /> */}
                                        <select name="Supplier" value={requireData?.Supplier} onChange={handleInputChange}>
                                        <option value={-1}></option>
                                            {isSupplier.map((item, index) => (
                                                <option key={index} value={item.supplier_id}>{item.supplier_code} | {item.supplier_name}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className={css.EachInputDiv}>
                                        <label>地址</label>
                                        {/* <input type="text" value={requireData.Address} onChange={(e) => setRequireData({ ...requireData, Address: e.target.value })} /> */}
                                        <select name="Address" value={requireData?.Address} onChange={handleInputChange}>
                                            <option value=""></option>
                                            {isWareHouse?.type_1.warehouses.map((item, index) => (
                                                <option key={index} value={item.product_warehouse_id}>{item.warehouse_type} | {item.warehouse_location}</option>
                                            ))}
                                            {isWareHouse?.type_2.warehouses.map((item, index) => (
                                                <option key={index} value={item.product_warehouse_id}>{item.warehouse_type} | {item.warehouse_location}</option>
                                            ))}
                                            {isWareHouse?.type_3.warehouses.map((item, index) => (
                                                <option key={index} value={item.product_warehouse_id}>{item.warehouse_type} | {item.warehouse_location}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className={css.ProductListDiv}>
                                    <div className={css.AddProductControlDiv}>
                                        <button className={css.AddProductBtn} onClick={() => setModel(1)}>新增貨品</button>
                                    </div>

                                    <div className={css.ProductTableDiv}>

                                        <table id="ProductListTable" style={{ width: '100%' }}>
                                            <thead style={{ backgroundColor: '#FFB300' }}>
                                                <tr>
                                                    <th style={{ width: '8%', borderRight: '1px rgb(26, 26, 26) solid' }}>貨品編號</th>
                                                    <th style={{ width: '12%', borderRight: '1px rgb(26, 26, 26) solid' }}>貨品名稱</th>
                                                    <th style={{ width: '8%', borderRight: '1px rgb(26, 26, 26) solid' }}>數量</th>
                                                    <th style={{ width: '8%', borderRight: '1px rgb(26, 26, 26) solid' }}>狀態</th>
                                                    <th style={{ width: '10%' }}>指令</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cartData.map((cartItem, index) => {
                                                        const product = isData.find(prod => prod.Product_SKU_ID === cartItem.Product_Sku_ID);
                                                        if (!product) return null;
                                                        return (
                                                            <tr key={index}>
                                                                <td>{product.Product_Code_SKU}</td>
                                                                <td>{product.Product_Name}</td>
                                                                <td>{cartItem.Order_Amount}</td>
                                                                <td>{product.Product_Total_Stock > product.Product_Min_Stock ? '充足' : '缺貨'}</td>
                                                                <td>
                                                                    <button className={css.CartEditProduct} onClick={() => editProduct(product.Product_Code_SKU)}>編輯</button>
                                                                    <button className={css.CardDeleteProduct} onClick={() => RemoveProduct(cartItem.Product_Sku_ID)}>刪除</button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                            <div className={css.CreateTicketDiv}>
                                <button className={css.CreateTickButton} onClick={() => SendCreateOrderRes()}>建立訂單</button>
                            </div>
                        </div>
                    </>
                }

                {isModel === 1 &&
                    <>
                        <div className={css.BackgroundDiv}>
                            <div className={css.HeaderDiv}>
                                <p>查看貨品資料</p>
                                <div className={css.TopControlDiv}>
                                    <button className={css.DisplayProductButtonQTY} style={{ backgroundColor: '#49BC49' }} onClick={() => console.log(cartData)}>充足</button>
                                    <button className={css.DisplayProductButtonQTY} style={{ backgroundColor: '#BC4949' }}>缺貨</button>
                                </div>

                                <div className={css.SearchBar}>
                                    {/* <input type="text" id="SearchUserTxt" placeholder="搜尋" onKeyUp={handleSearchUser} /> */}
                                    <input
                                        type="text"
                                        id="SearchTableTxt"
                                        placeholder="搜尋"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm on input change
                                    />
                                    <i className="fi fi-bs-search" />
                                </div>
                                <div className={css.shopingCart}>
                                    <i className="fi fi-bs-shopping-cart" />
                                </div>
                                <i className="fi fi-br-times-hexagon" onClick={() => setStockCreateTicketShow(false)} />
                            </div>

                            <div className={css.ProductTypeOptionDiv}>
                                {typedata.map((ProductType) => (
                                    <div key={ProductType.product_type_id} className={css.ProductTypeFieldset}>
                                        <input
                                            type="checkbox"
                                            id={ProductType.product_type_code}
                                            name={ProductType.product_type_code}
                                            checked={selectedTypes.includes(ProductType.product_type_name)} // Check if selected
                                            onChange={() => handleTypeSelection(ProductType.product_type_name)} // Handle selection change
                                        />
                                        <label htmlFor={ProductType.product_type_code} className={css.Typelable}>
                                            {ProductType.product_type_name}
                                        </label>
                                    </div>
                                ))}
                            </div>

                            <div className={css.SelectProductListDiv}>
                                {filteredData?.map((product, index) => (
                                    <div key={product.Product_SKU_ID} className={css.EachProductItemDiv} style={{ backgroundColor: product.Product_Total_Stock > product.Product_Min_Stock ? '#E9FFE9' : '#FFE9E9' }}>
                                        <div className={css.ProductImgDiv}>
                                            {product.Product_Img !== '' && product.Product_Img !== null ? (
                                                <>
                                                    <img src={product.Product_Img} alt="Product Img " />
                                                </>
                                            ) : (
                                                <>
                                                    <img src={DefaultImage} alt="Product Deafult " />
                                                </>
                                            )}

                                        </div>
                                        <div className={css.ProductNameDiv}>
                                            {product.Product_Code_SKU} - {product.Product_Name}
                                        </div>
                                        <div className={css.ProductDescDiv}>
                                            {product.Product_Desc}
                                        </div>
                                        <div className={css.ProductStockStatusDiv}>
                                            <i className="fi fi-ss-dot-circle" style={{ color: product.Product_Total_Stock > product.Product_Min_Stock ? '#5DD104' : '#D10408' }} />
                                            {product.Product_Total_Stock > product.Product_Min_Stock ? '充足' : '缺貨'}
                                        </div>

                                        {product.Product_Max_Stock - product.Product_Total_Stock > 0 && (
                                            <div className={css.ProductFullStockDiv} onClick={() => handleQuantityChange(product.Product_SKU_ID, product.Product_Max_Stock - product.Product_Total_Stock)}>
                                                <p>保滿存貨</p>
                                            </div>
                                        )}

                                        {Math.floor(product.Product_Max_Stock * 0.8) - product.Product_Total_Stock > 0 && (
                                            <div className={css.Product80StockDiv} onClick={() => handleQuantityChange(product.Product_SKU_ID, Math.floor(product.Product_Max_Stock * 0.8) - product.Product_Total_Stock)}>
                                                <p>8成存貨</p>
                                            </div>
                                        )}

                                        {Math.floor(product.Product_Max_Stock * 0.5) - product.Product_Total_Stock > 0 && (
                                            <div className={css.Product50StockDiv} onClick={() => handleQuantityChange(product.Product_SKU_ID, Math.floor(product.Product_Max_Stock * 0.5) - product.Product_Total_Stock)}>
                                                <p>5成存貨</p>
                                            </div>
                                        )}

                                        <div className={css.ProductOrderValueDiv}>
                                            <div className={css.ProductOrderAddDiv}>
                                                <i className="fi fi-sr-add"
                                                    onClick={() =>
                                                        handleQTYChange(
                                                            'add',
                                                            product.Product_SKU_ID
                                                        )
                                                    }
                                                />
                                            </div>
                                            <input
                                                name="OrderQty"
                                                type="number"
                                                placeholder="數量"
                                                value={cartData.find(item => item.Product_Sku_ID === product.Product_SKU_ID)?.Order_Amount || ''}
                                                onChange={(e) =>
                                                    handleQuantityChange(
                                                        product.Product_SKU_ID,
                                                        Number(e.target.value)
                                                    )
                                                }
                                            />
                                            <div className={css.ProductOrderMinusDiv}>
                                                <i className="fi fi-ss-minus-circle"
                                                    onClick={() =>
                                                        handleQTYChange(
                                                            'minus',
                                                            product.Product_SKU_ID
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className={css.ButtonContrlDiv}>
                                <button className={css.AddProductBtn} onClick={() => setModel(0)}>確認</button>
                            </div>
                        </div>
                    </>
                }

            </ section>
        </>
    )

    return content
}

export default StockCreateTicket